import React from "react";
import "../App.scss";

export default function ProjectItem(props) {
  const { projectItem, viewDetail } = props;

  return (
    <div
      style={{ width: "350px", height: "auto" }}
      className="card text-left mt-2"
    >
      <img
        style={{ width: "100%" }}
        className="card-img-top"
        src={projectItem.image}
        alt={projectItem.projectName}
      />
      <div className="card-body" style={{ height: "100px" }}>
        <h3 className="card-title">{projectItem.projectName}</h3>
      </div>
      <button
        onClick={() => {
          viewDetail(projectItem);
        }}
        data-toggle="modal"
        data-target="#modelId"
        className="btn btn-success text-white"
      >
        View Detail
      </button>
    </div>
  );
}
