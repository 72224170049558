import React from "react";
import ProjectItem from "./ProjectItem";
import dataProjects from "../Data/data.json";
import "../App.scss";

export default function ProjectList(props) {
  let projectList = dataProjects;

  const { viewDetail } = props;

  const renderProjectList = () => {
    return projectList.map((project, index) => {
      return (
        <div className="box" key={index}>
          <ProjectItem projectItem={project} viewDetail={viewDetail} />
        </div>
      );
    });
  };

  return (
    <div>
      <div className="box-container">{renderProjectList()}</div>
    </div>
  );
}
