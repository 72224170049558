import React from "react";
import "./About.scss";

export default function About() {
  return (
    <div className="about">
      <h1 className="heading">
        <span>About Me</span>
      </h1>
      <div className="biography">
        <div className="bio">
          <div>
            <p>
              I'm Crystal, an embryologist and a self-taught software engineer
              with a degree in Biochemistry and Molecular Biology. I have always
              been interested in technology but didn't always have access to it
              when I was younger since many people still think tech jobs are for
              men and this misconception about tech causes many women to
              overlook the opportunities available in the digital world, which
              had happened to me. And through high school and college, I focused
              more on biology and science pursuits.
            </p>
            <p>
              After college, I found a job in the Clinical IVF Lab and got
              trained to become an embryologist. Embryology is a very meaningful
              job, helping people build their families but the job doesn’t make
              me feel like I am fulfilling my life purpose like I am living up
              to my full potential. While I was succeeding in my roles, they
              lacked the creativity and problem-solving that I craved. I was
              bored. I didn’t feel engaged at work. One of my favorite hobbies,
              however, is playing piano, which I've been playing since I was
              eight. One of the things that kept me interested, besides its
              beautiful sound and melody, was that every pianist will have their
              own style and creativity to play the song. Then, I met a group of
              friends in tech. They taught me how software development worked
              and that there were many meaningful jobs out there for me. Thanks
              to their influence, I decided embryology wasn’t for me — and that
              coding was. That drove me to learn programming in order to do more
              creative projects, and hopefully, one day build my own projects
              with it. I believe women can excel in anything if they strive hard
              enough.
            </p>

            <p>
              That was January 2020. The more involved I was in tech, the more I
              loved the problems being solved and energy from those in the
              business. I first taught myself HTML/CSS, which came easy for me
              thanks to my natural artistic abilities. Then I shifted to
              learning JavaScript, React when I realized my vision for apps
              spanned well beyond the designing prototypes.
            </p>
          </div>

          <h3>
            <span>Why Front End Development? </span>
          </h3>
          <div>
            <p>
              My friend, a Full Stack Developer, had shown me his portfolio
              projects and I was impressed by his works. So I was inspired to
              learn more about Front End Development and now here I am, I fall
              in love with it.
            </p>
            <p>
              I find that it is rewarding with Front End Engineering because by
              nature, the Front End Engineering involves building websites or
              apps that I am able to see and interact with. I love the idea of
              designing things and using code to turn it into a real product
              that is responsive, accessible and many people enjoy using it.
            </p>
          </div>
          <h3>
            <span>Work Ethic and Qualifications </span>
          </h3>
          <div>
            <p>
              My biggest advantage is that I don’t have a traditional CS degree
              or graduated from a bootcamp. I have true passions for coding and
              solving complex solutions so I am not motivated by an educational
              institution; I am motivated from within. I push myself hard, and
              there’s no greater desire to succeed than from someone who is
              self-taught. I am an enthusiastic, professional, loyal, and
              hard-working person who has been working toward becoming a Front
              End Developer for at least two years now. I desire to continually
              improve and develop to stay at the top within the Front End
              Development industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
