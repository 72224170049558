import React from "react";
import "./Skills.scss";

export default function Skills() {
  return (
    <div>
      <div className="skills">
        <h1 className="heading">
          <span>skills</span>
        </h1>
        <div className="box-container">
          <div className="box">
            <img src="images/js_html_css.png" alt="js_html_css_logo" />
            <h3>HTML5 - JS - CSS3</h3>
          </div>
          <div className="box">
            <img src="images/react.png" alt="react_logo" />
            <h3>React</h3>
          </div>
          <div className="box">
            <img src="images/redux.png" alt="redux_logo" />
            <h3>Redux</h3>
          </div>
          <div className="box">
            <img src="images/typescript_logo.png" alt="typescript_logo" />
            <h3>TypeScript</h3>
          </div>
          <div className="box">
            <img src="images/nodejs_logo.png" alt="nodejs_logo" />
            <h3>Node</h3>
          </div>
          <div className="box">
            <img src="images/mongodb.jpeg" alt="mongodb_logo" />
            <h3>MongoDB</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
