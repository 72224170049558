import React from "react";
import "./Contact.scss";

export default function Contact() {
  return (
    <div>
      <div className="contact">
        <h1 className="heading">
          <span>contact me</span>
        </h1>
        <div className="box-container">
          <div className="box">
            <i className="fas fa-phone" />
            <h3>Phone</h3>
            <p>669-226-7581</p>
          </div>
          <div className="box">
            <i className="fas fa-envelope" />
            <h3>Email</h3>
            <p>crystalhanguyen@gmail.com</p>
          </div>
          <div className="box">
            <i className="fas fa-map-marker-alt" />
            <h3>Address</h3>
            <p>San Jose, CA</p>
          </div>
        </div>
      </div>
    </div>
  );
}
