import React from "react";
import "./Home.scss";

export default function Home() {
  return (
    <div className="home">
      <div className="image">
        <img src="images/Crystal.jpg" />
      </div>
      <div className="content">
        <h3>
          Hi! I'm Crystal (Camha) Nguyen
          <span>Front End Developer based in San Francisco Bay Area</span>
        </h3>

        <a
          href="https://drive.google.com/file/d/1rwn7hxbfcxQCHxdnmIBYuhqmjbQ3M_CV/view?usp=sharing"
          className="btn"
        >
          Download Resume
        </a>
      </div>
    </div>
  );
}
