import React, { useState } from "react";
import Modal from "../../components/Modal";
import ProjectList from "../../components/ProjectList";
import "../../App.scss";

export default function Projects() {
  const projectDetail = {
    id: 1,
    projectName: "Virtual Dressing Room",
    description:
      "Virtual Fashion Website allow users to try on clothes virtually",
    technology: "React, Redux, Functional Component, React Hooks, React Spring",
    url: "https://19-virtual-dressing-room.vercel.app/",
    image: "",
  };

  const [state, setState] = useState(projectDetail);

  const viewDetail = (newItem) => {
    setState(newItem);
  };

  return (
    <div className="portfolio">
      <h1 className="heading">
        <span>portfolio</span>
      </h1>
      <Modal content={state} />
      <ProjectList viewDetail={viewDetail} />
    </div>
  );
}
